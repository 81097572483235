import React, { useState, useEffect } from 'react';
import axios from 'axios';
import numeral from 'numeral';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ButtBotLogo from '../components/ButtBotLogo';
import Icon from '../components/Icon';
import Button from '../components/Button';

const IndexPage = () => {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      const res = await axios.get(`https://api.buttbot.net`);
      const { data: stats } = res;
      setStats(stats);
    };

    fetchStats();
  }, []);

  return (
    <Layout>
      <SEO keywords={[`buttbot`, `discord`, `discord bot`]} />
      <div className="flex justify-center">
        <ButtBotLogo />
      </div>
      <div className="text-center text">
        <h1 className="mb-0">ButtBot for Discord</h1>
        <h2 className="page-sub-title">Now with Butt AI&trade;</h2>
      </div>
      <div className="text-center mb-4">
        <span>
          <strong>Current Version:</strong>{' '}
          {stats ? stats.version : 'Loading...'} |{` `}
        </span>
        <span>
          Currently on{' '}
          <strong>
            {numeral(stats ? stats.totalServers : 0).format('0,0')} servers
          </strong>{' '}
          |{` `}
        </span>
        <span>
          Buttified{' '}
          <strong>
            {numeral(stats ? stats.buttifyCount : 0).format('0,0')} messages
          </strong>
        </span>
      </div>
      <p className="text-center mb-4 md:w-2/3">
        I bet you are wondering... <strong>What is ButtBot?</strong> Good
        question. This bot will randomly choose messages in channels that you
        whitelist and change some of the words to butt. Yep. That's it.
      </p>
      <p className="text-center mb-4 md:w-2/3">
        ButtBot is the most important thing your server doesn't have. But that's
        okay. We can fix that. Click the "Invite to your server" link below to
        add it to your server! If you want to know how things work after adding
        it, trigger the built-in help command with <strong>?butt help</strong>{' '}
        or click the "Guide" button below.
      </p>
      <div className="justify-center my-4 w-100 text-center">
        <Button
          type="a"
          href="https://discordapp.com/oauth2/authorize?client_id=438269159126859776&scope=bot&permissions=93248"
          iconName="sign-in-alt"
        >
          Invite to your Server
        </Button>
        <Button
          to="/guide"
          iconName="book"
        >
          Guide
        </Button>
        <Button
          type="a"
          href="https://discord.gg/uqSuumF"
          iconName={['fab', 'discord']}
        >
          Join The Discord
        </Button>
      </div>
      <p className="page-footer mt-4 text-center">
        Created with <Icon name="heart" className="text-red-600" /> by{' '}
        <a
          href="https://twitter.com/sctx"
          target="_blank"
          rel="noopener noreferrer"
        >
          @sctx
        </a>
        . Source available on{' '}
        <a
          href="https://github.com/sct/buttbot-discord"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name={['fab', 'github']} /> GitHub
        </a>
        .
      </p>
    </Layout>
  );
};

export default IndexPage;
